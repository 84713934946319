import api from "@/base/utils/request";

// 获取留言列表
export const categoryList = data => {
  return api({
    url: "admin/cyc/askCategory/index",
    method: "post",
    data
  });
};
// 保存分类
export const saveCategory = data => {
  return api({
    url: "/admin/cyc/askCategory/save",
    method: "post",
    data
  });
};

// 删除分类
export const delCategory = data => {
  return api({
    url: "/admin/cyc/askCategory/delete",
    method: "post",
    data
  });
};

