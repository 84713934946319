<!--
 * @Author: dongjia
 * @Date: 2021-06-25 16:57:10
 * @LastEditTime: 2021-06-25 18:07:54
 * @LastEditors: aleaner
 * @Description: 商品分类列表管理
 * @FilePath: \saas-admin-vue\src\modules\wx-store\views\product-category.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page">
    <el-button
      class="top-btn"
      size="small"
      type="primary"
      @click="showAddCategory = true"
      >添加</el-button
    >

    <el-table
      :data="categoryList"
      class="thead-light"
      v-loading="showLoading"
      stripe
    >
      <el-table-column
        v-for="(item, index) in categoryTable"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.min_width"
        :sortable="item.sortable"
      >
        <template slot-scope="scope">
          <span>{{ scope.row[item.prop] | placeholder }}</span>
        </template>
      </el-table-column>
 
      <el-table-column label="操作" width="170" fixed="right">
        <template slot-scope="scope">
          <el-button
            style="color: #3576ff"
            size="small"
            type="text"
            @click="handleEditCategory(scope.row)"
            >编辑
          </el-button>
          <el-button
            style="color: #3576ff"
            size="small"
            type="text"
            @click="handleDelCategory(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <AddCategory
      :showAddCategory="showAddCategory"
      :editObj="editObj"
      @closeAddDialog="closeAddDialog"
      @updateData="updateData"
    ></AddCategory>

  </div>
</template>

<script>
import {
  categoryList,

  delCategory,

} from "../api/category-list";

import AddCategory from "../components/AddCategory";

export default {
  data() {
    return {
      categoryForm: {},
      pageData: {},
      categoryTable: [{ label: "类别", prop: "name", min_width: 80 }],

      categoryList: [],
      selectArr: [],
      goods_count_arr: [],
      showLoading: false,
      showAddCategory: false,
      editObj: {},

    };
  },
  components: {
    AddCategory,
  },
  created() {
    this.getCategoryList(this.categoryForm);
  },
  methods: {
    handleDelCategory(data) {
      let delTitle = "";
      let delBtn = "";

      delTitle = "确认要将该分类删除吗？删除后无法恢复。";
      delBtn = "删除";

      this.$confirm(delTitle, "提示", {
        confirmButtonText: delBtn,
        cancelButtonText: "取消",
        type: "error",
      })
        .then((res) => {
          this.showLoading = true;
          delCategory({ id: data.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.RefreshList();
            })
            .catch((err) => {
              this.showLoading = false;
            });
        })
        .catch((err) => {});
    },

    closeAddDialog() {
      this.showAddCategory = false;
      this.editObj = {};
    },
    handleEditCategory(data) {
      this.editObj = JSON.parse(JSON.stringify(data));
      this.showAddCategory = true;
    },
    updateData() {
      this.showAddCategory = false;
      this.RefreshList();
    },


    // 刷新列表
    RefreshList() {

      this.getCategoryList();
    },
    getCategoryList(requestData) {
      this.showLoading = true;
      this.pageData = {};
      categoryList(requestData)
        .then((res) => {
          this.categoryList = res.data;

          this.showLoading = false;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="scss"></style>
